<template>
  <div id="znl-slide">
    <div id="head">
      <div id="fw-logo" :class="[!flag ? 'logoHide' : '']">
        <a href="/"><img src="~assets/images/znl-logo.png" /></a>
      </div>
      <div id="showHidden" @click="show" v-if="false">
        <a id="fw-bars" v-show="flag" title="收起" href="#">
          <!-- <img src="~assets/images/left_nav_pic/hide_btn.png" /> -->
          <i class="iconfont icon-left_nav_hide_btn"></i>
        </a>
        <a id="fw-bars" v-show="!flag" title="展开" href="#">
          <!-- <img src="~assets/images/left_nav_pic/show_btn.png" /> -->
          <i class="iconfont icon-left_nav_show_btn"></i>
        </a>
      </div>
    </div>
    <!-- <EasyScrollbar class="contain" :barOption="barOption" :noresize="true"> -->
    <div id="znl-scroll" :style="scrollStyle()">
      <el-menu
        :mode="flag ? 'vertical' : 'horizontal'"
        :class="[flag ? '' : 'horizontal']"
        @open="openCloseCallBack(true)"
        @close="openCloseCallBack(false)"
      >
        <el-submenu
          v-for="(item, i) in $router.options.routes"
          v-if="displayMenu(item.meta)"
          :key="item.name"
          :index="i + ''"
        >
          <template slot="title">
            <i
              :class="['iconfont', item.meta.icon ? item.meta.icon : '']"
              :style="iconStyles"
            ></i>
            <span :class="{ hidd: !flag }">{{ item.meta.title }}</span>
          </template>
          <!--   v-ripple.click.200="'rgba(238, 119, 0, 0.2)'" -->
          <el-menu-item
            v-for="(child, j) in item.children"
            v-if="displayMenu(child.meta)"
            :key="child.name"
            :index="i + '-' + j"
            :route="{ name: child.name }"
            @click="isClick(j, child)"
          >
            <router-link :to="{ name: child.name }" active-class="is-active">
              {{ child.meta.title }}</router-link>
          </el-menu-item>
        </el-submenu>
      </el-menu>

<!--      <div class="logoff-box">-->
<!--        <a @click="logoff()">注销</a>-->
<!--      </div>-->
    </div>
    <!-- </EasyScrollbar> -->
  </div>
</template>

<script>
import { Loading } from "element-ui";
// import EasyScrollbar from 'easyscroll'
import { getDocumentRect, setStyle, delCookie } from "~assets/scripts/utils/dom";
export default {
  name: "SideBar",
  data() {
    return {
      flag: true,
      isIndex: "",
      indexs: [],
      isLoading: false,
      iconStyles: { "margin-left": "0px;" },
      elMenuHeight: 0,
      domRect: {},
      barOption: {
        barColor: "rgba(180,180,180,0.8)", // 滚动条颜色
        barWidth: 6, // 滚动条宽度
        railColor: "transparent", // 导轨颜色
        barMarginRight: 0, // 垂直滚动条距离整个容器右侧距离单位（px）
        barMaginBottom: 0, // 水平滚动条距离底部距离单位（px)
        barOpacityMin: 0, // 滚动条非激活状态下的透明度
        zIndex: "auto", // 滚动条z-Index
        autohidemode: true, // 自动隐藏模式
        horizrailenabled: false, // 是否显示水平滚动条
      },
    };
  },
  components: {
    Loading,
    // EasyScrollbar
  },
  watch: {
    flag(val) {
      let arrows = this.$el.querySelectorAll(".el-submenu__icon-arrow");
      if (!val) {
        _.each(arrows, (item) => {
          // item.classList.add('hidd')
          item.style.display = "none";
        });
      } else {
        _.each(arrows, (item) => {
          // item.classList.remove('hidd')
          item.style.display = "block";
        });
      }
      this.iconStyle(val);
      let idLeft = document.getElementById("fw-left");
      if (val) {
        setStyle(idLeft, "overflow-x", "hidden");
        setStyle(idLeft, "overflow-y", "auto");
      } else {
        setStyle(idLeft, "overflow-x", "inherit");
        setStyle(idLeft, "overflow-y", "inherit");
      }
    },
    elMenuHeight(height) {
      let scrollbar = this.$el.querySelector(".easy-scrollbar__thumb");
      if (height < this.domRect.height - 55) {
        scrollbar.style.display = "none";
      } else {
        scrollbar.style.display = "block";
      }
    },
  },
  methods: {
    displayMenu(opts) {
      let {display, authName, alwaysShow} = opts;
      if(alwaysShow)  return true;
      const pageRoles = this.$store.state.accountInfo.pageRoles;
      if (display === false) {
        return false;
      } else {
        return _.isUndefined(authName) || _.includes(pageRoles, authName);
      }
    },
    displayMenuOld(display, authName) {
      const pageRoles = this.$store.state.accountInfo.pageRoles;
      if (display === false) {
        return false;
      } else {
        return _.isUndefined(authName) || _.includes(pageRoles, authName);
      }
    },
    openCloseCallBack(val) {
      // _.delay(() => {
      //   this.elMenuHeight = this.$el.querySelector('#znl-scroll>.el-menu').offsetHeight
      // }, 600)
    },
    scrollStyle() {
      const domRect = getDocumentRect();
      this.domRect = domRect;
      return {
        // height: domRect.height - 55 + "px",
      };
    },
    iconStyle(val) {
      if (val) {
        this.$set(this.iconStyles, "margin-left", "0px");
        this.$set(this.iconStyles, "margin-right", "10px");
      } else {
        this.$set(this.iconStyles, "margin-left", "135px");
        this.$set(this.iconStyles, "margin-right", "17px");
      }
    },
    isClick(j, child) {
      let znlLable = this.$el.parentElement.parentElement.querySelector(
        ".znl-lable" + child.name.split("/")[1]
      );
      znlLable && (znlLable.parentElement.style.display = "block");
    },
    show() {
      this.$store.state.siderbarHide = !this.$store.state.siderbarHide;
      this.flag = !this.flag;
    },

    logoff () {
      this.$confirm('确定退出系统吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        localStorage.clear();
        delCookie('login_token');

        const url = 'Security/SignOut'
        this.$post(url, (data, logic) => {
          // if (logic.code === 200) {
          //   this.$message({
          //     message: '重置操作成功,需重新登录才生效!',
          //     type: 'success'
          //   })
          // } else {
          //   this.$message({
          //     message: '操作失败,请联系系统管理员',
          //     type: 'error'
          //   })
          // }
        })
        this.$router.push("/login")
        this.$store.state.tabs = [];
      })
    }
  },
};
</script>

<style lang="scss">
@import "app/assets/styles/_variables.scss";

#head {
  height: 50px;
  line-height: 50px;
  padding: 0px 10px 0 00px;
  border-bottom: 1px solid #3f4f66;

  #fw-logo {
    float: left;
    margin-left: 10px;
    &.logoHide {
      visibility: hidden;
    }
  }
  img {
    vertical-align: middle;
    width: 100px;
    height: 30px;
  }
  #fw-bars {
    float: right;
    margin-right: -10px;
    .iconfont {
      color: #fff;
      font-size: 24px;
    }
  }
  #showHidden {
    display: flex;
    justify-content: space-around;
  }
}
// #znl-scroll{
//   height: 500px;
// }
#znl-scroll > .el-menu--horizontal {
  border-bottom: none !important;
}
.hidd {
  display: none;
}
.el-menu {
  border-right: none;

  .el-submenu {
    background-color: lighten($black, 40%);
    background-color: #313d4f !important;
    position: relative;
    border-bottom: 1px solid #3f4f66;
    &:hover {
      background: #495b75 !important;
    }
    .el-menu-item,
    .el-submenu__title {
      height: 40px !important;
      line-height: 40px !important;
      font-size: 14px !important;
      background-color: #313d4f !important;
      font-family: Tahoma, Arial, Microsoft YaHei, "微软雅黑", simsun, "宋体",
        HeiTi, "黑体", sans-serif;
      color: lighten($black, 80%);
      padding-left: 20px !important;
      padding-right: 0px !important;
      display: flex;
      align-items: center;
      .iconfont:first-child {
        margin-right: 10px;
      }
      .el-submenu__icon-arrow {
        margin-top: -4px;
        font-size: 12px;
        color: #999;
      }
      &:hover {
        background-color: #4f6380 !important;
      }
    }

    &.is-opened .el-submenu__title {
      color: white;
      position: relative;
    }
    // .el-menu{
    //   display: none;
    // }
    img {
      vertical-align: middle;
      // margin-right: 10px;
      height: 24px;
      margin-top: -4px;
    }
    .el-menu-item {
      padding-left: 0px !important;
      padding-right: 0px !important;
      font-size: 14px !important;
      background-color: #3f4f66 !important;
      min-width: 160px !important;
      &:hover {
        background-color: #4f6380 !important;
      }

      a {
        padding-left: 46px;
        padding-right: 45px;
        display: block;
        color: white;
        text-decoration: $link-hover-decoration;
        position: relative;

        &.is-active:before {
          content: " ";
          width: 10px;
          height: 10px;
          // background-color:$brand-primary;
          border-radius: 50%;
          position: absolute;
          left: 30px;
          top: 50%;
          background-color: #ed9e00 !important;
          transform: translateY(-50%);
        }
      }
    }
  }
  &.horizontal {
    .el-submenu {
      left: -28px;
      &.is-active,
      &:hover {
        .el-submenu__title {
          border: none;
        }
      }
    }
    &:hover {
      .el-menu {
        display: block;
      }
    }
    .el-menu {
      position: absolute;
      left: 183px !important;
      padding: 0 !important;
      top: 38px !important;
      border: none !important;
      transform: translateY(-40px);
      width: 150px;
      overflow: hidden;
      padding: 0;
      min-width: 150px !important;
      display: none;
      .el-menu-item {
        a {
          padding: 0 30px;
          &.is-active::before {
            left: 15px;
          }
        }
        &.is-active {
          a::before {
            left: 15px;
            background-color: #ed9e00 !important;
          }
        }
      }
    }
    .el-menu--horizontal {
      border-bottom: none !important;
    }
  }
}
.el-menu--horizontal .el-submenu .el-submenu__title {
  border-bottom: 0px solid transparent !important;
}
.hideElmenu {
  overflow: inherit;
  position: absolute;
  top: 0px;
  left: 160px;
  z-index: 999;
}
.logoff-box {
  bottom: 2px;
  position: absolute;
  color: rgb(255, 255, 255);
  text-align: center;
  width: 100%;
  line-height: 40px;
  background-color: #313d4f;

  a {
    color: #fff;
    &:hover {
      color: #fff;
    }
  }
}
</style>
