<template>
  <div>
    <div id="fw-content" :style="{top:(hideMenu?0:domRect.headerHeight) +'px'}">
      <div
        id="fw-left"
        :style="{
             width:domRect.siderWidth+'px',
             display:hideMenu?'none':'',
             left:siderbarHide?(0-domRect.siderWidth+50)+'px':0,
             overflow: siderbarHide? 'inherit': '',
             'overflow-y':siderbarHide? '': 'auto',
             'overflow-x':siderbarHide? '': 'hidden'}">
        <znl-sidebar></znl-sidebar>
      </div>
      <div
        id="fw-right"
        :style="{left:hideMenu?0:(siderbarHide?50+'px':domRect.siderWidth+'px')}"
        @wheel="mouseWheel">
        <znl-tabs :show-tabs="!hideMenu"></znl-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import znlHeadbar from "~components/layouts/znl-headbar";
import znlSidebar from "~components/layouts/znl-sidebar";
import znlTabs from "~components/layouts/znl-tabs";
import { isClient } from "~/lib/runtime";

export default {
  name: "DefaultLayout",
  components: {
    znlHeadbar,
    znlSidebar,
    znlTabs
  },
  created() {
    this.hideMenu = !!isClient();
  },
  data() {
    return {
      hideMenu: false // 是否隐藏左侧菜单
    };
  },
  computed: mapState(["siderbarHide", "domRect"]),
  methods: {
    mouseWheel(e) {
      this.$emit("mouse-wheel", e);
    }
  },
  mounted() {
    // console.log(this)
  }
};
</script>

<style lang='scss' scoped>
@import "app/assets/styles/variables";
@import "app/assets/styles/mixins";
#app {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  // #fw-header {
  //   background-color: lighten($black, 40%);
  // }
  // #fw-content {
  //   position: absolute;
  //   left: 0;
  //   right: 0;
  //   bottom: 0;

  #fw-left {
    // background-color: lighten($black, 40%);
    background-color: #313d4f;
    position: absolute;
    top: 0;
    bottom: 0;
    // overflow-x: hidden;
    // overflow: inherit;
    // overflow-y: auto;
    z-index: 999;
    box-shadow: 2px 0 8px 0 rgba(0, 0, 0, 0.26);
    @include transition(left 0.5s);
  }

  #fw-right {
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: #e6e6e6;
    min-width: 1024px;
    @include transition(left 0.5s);
    // .el-tabs--border-card{
    //   border: none;
    // }
    &:before {
      content: " ";
      clear: both;
    }
  }
}
</style>
