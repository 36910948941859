<template>
  <div>
    <div id="fw-logo" >
      <a href="/" v-show="$store.state.siderbarHide">
        <img src="~assets/images/logo.png" />
      </a>
    </div>
    <a id="fw-bars"
       href="#"
       @click.stop.prevent="$store.state.siderbarHide=!$store.state.siderbarHide">
      <icon name="bars"></icon>
      <span class="sr-only">Silder Bar</span>
    </a>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default{
  name: 'HeadBar',
  data () {
    return {}
  },
  computed: mapState([
    'siderbarHide'
  ])
}
</script>

<style lang="scss" scoped>
@import 'app/assets/styles/_variables.scss';
#fw-logo {
  width: 160px;
  text-align: center;
  float: left;
  a {
    img {
      margin: 5px auto;
      height: 30px;
    }
  }
}

#fw-bars {
  .fa-icon {
    height: 40px;
    padding: 0 8px;
    color: lighten($black, 85%);

    &:hover {
      background-color: lighten($black, 60%);
    }
  }
}
</style>
