<template>
  <div :class="['znl-tabs-container', showTabs ? 'showTabs' : 'hideTabs']">
    <el-tabs
      ref="tabs"
      :value="activeTab"
      class="znl-header-tabs p-header-tabs"
      id="p-header-tabs"
      type="border-card"
      @tab-click="changeTab"
      @tab-remove="closeTab"
      @contextmenu.native="onContextMenuShow">
      <el-tab-pane
        v-for="(item,index) in newTabs"
        :key="item.name"
        :class="['znl-tabpane'] "
        id="p-headers-tabs-contents"
        :closable="item.closable"
        :name="item.name"
        :style="{height:(showTabs ? domRect.contentHeightWithoutTab : domRect.contentHeightWithoutTab + 50) + 'px'}"
        element-loading-text="拼命加载中">


        <span slot="label" :class="['label']"><i
          :class="['iconfont',item.closable?'icon-nav_unlock_ic':'icon-nav_lock_ic']"
          :style="{display: item.name==='Home'?'none':'inline', padding:'0 3px', }"
          @click="tabFixChange(index)"></i>{{ item.title }}</span>


        <component
          :is="item.components[item.components.length-1].default"
          v-if="!item.isIframe"
          :tag-name="item.name"
          style="padding:12px;min-width:860px"
        ></component>
        <component
          :is="item.components[item.components.length-1].default"
          v-if="item.isIframe"
          :height="(domRect.contentHeightWithoutTab -6)+'px'"
          frameborder="0"
          width="100%"
        ></component>

      </el-tab-pane>
    </el-tabs>
    <div class="znl-tabs-container_info" v-if="!isClient">
      <section class="znl-tabs-container_info-box">
        <span title="点击打开QQ在线客服">
          <a style='color: #fff' href="https://wpa.qq.com/msgrd?v=3&amp;uin=800106568&amp;site=qq&amp;menu=yes" target="_blank"><i class="qq-link"></i>客服</a>
        </span>
        <span style="cursor: default" :title="hoverName">
          <span style="margin-right:0">{{useInfo.UserName}}</span>
          <span style="color:#f1953e">@{{useInfo.CompanyName}}</span>
        </span>
        <span @click="onRes">注销</span>
      </section>
    </div>
    <div
      v-show="rightMenuConfig.show"
      ref="contextMenu"
      v-focus="rightMenuConfig.show"
      v-move-to.body
      :style="{left:rightMenuConfig.left + 'px',top:rightMenuConfig.top + 'px'}"
      class="znl-right-menu"
      @blur="onContextMenuHide"
      @keyup.esc="onContextMenuHide"
    >
      <ul>
        <li v-if="closable">
          <i class="iconfont icon-nav_lock_ic"></i>
          <a @click="tabFixChangeRight(tabFixChangeIndex)">锁定</a>
        </li>
        <li v-if="!closable">
          <i class="iconfont icon-nav_unlock_ic"></i>
          <a @click="tabFixChangeRight(tabFixChangeIndex)">解除锁定</a>
        </li>
        <li v-if="closable">
          <i class="iconfont icon-nav_close_btn_ic"></i>
          <a @click="closeTab($refs.tabs.currentName)">关闭</a>
        </li>
        <li>
          <i class="iconfont icon-nav_all-close_btn_ic"></i>
          <a @click="closeAllTab">全部关闭</a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import {mapState} from "vuex";
import {mixin as propSyncMixin} from "~assets/scripts/mixins/propSync";
import {mixin as moveToMixin} from "~assets/scripts/directives/moveTo";
import {mixin as focusMixin} from "~assets/scripts/directives/focus";
import {delCookie, hasClass} from "~assets/scripts/utils/dom";
import {Loading} from "element-ui";
// import LinkAll from "~components/layouts/linkAll.vue";
import {isClient} from "~/lib/runtime";
import {dateTimeFormat} from "@scripts/methods/common";

const CONFIG = {
  saveURL: "UserTabLock/AddUserTabLock",
  deleteURL: "UserTabLock/DeleteUserTabLock"
};

// const headersDom = document.getElementById('p-header-tabs')
//
// const firstDom = headersDom.firstChild
// console.log(firstDom)

export default {
  name: "TabsBar",
  mixins: [propSyncMixin, moveToMixin, focusMixin, dateTimeFormat],
  data() {
    return {
      isClient: isClient(),
      showTabs: false, // 是否显示标签页
      inquiryShow: false,
      rightMenuConfig: {
        show: false,
        left: 0,
        top: 0
      },
      tabFixChangeIndex: "",
      closable: false,
      activeName: "Home"
    };
  },
  props: {},
  components: {
    Loading
    // LinkAll
  },
  computed: {
    ...mapState(["activeTab", "tabs", "domRect", "useInfo"]),
    hoverName () {
      let msg = '所在服务器: ' + this.useInfo.SNo + '\n服务期限至: ' + this.getDate(this.useInfo.ExpirationTime);
      return msg;
    },

    newTabs() {
      const filterRouters = ['LoginView', 'register', 'resetPassword']
      return this.tabs.filter(item => !filterRouters.includes(item.name))
    }
  },

  watch: {
    $route(value) {
      this.$nextTick(() => {
        this.refreshGrid(_.find(this.$refs.tabs.panes, tab => tab.active));
      });
    }
  },
  methods: {
    concantsClick(type) {
      if (!this.$store.state.isLoginBomaiSuccess) {
        return this.$message({message: "未绑定Bomai账号！", type: "warning"});
      }
      if (type === "concants") {
        this.chatShow = true;
        this.inquiryShow = false;
      } else {
        this.chatShow = false;
        this.inquiryShow = true;
      }
    },
    changeTab(tab) {
      const activeTab = _.find(this.$store.state.tabs, item => {
        return item.name === tab.name;
      });
      console.log(this.$store.state.tabs)
      this.closable = tab.closable;
      this.tabFixChangeIndex = tab.index;
      this.$store.state.activeTab = tab.name;
      this.$router.replace({name: tab.name, query: activeTab.query});
      if (this.$store.state.loadingInstance) {
        this.$store.state.loadingInstance.close();
      }
    },
    findActiveTab(name) {
      _.each(this.tabs, (tab, index) => {
        if (tab.name === name) {
          let nextTab = this.tabs[index - 1] || this.tabs[index + 1];
          if (nextTab) {
            this.activeName = nextTab.name;
          }
        }
      });
      // if (this.$el.querySelector('.znl-lable' + this.activeName.split('/')[1]).parentElement.style.display === 'none') {
      //   this.findActiveTab(this.activeName)
      // }
    },
    closeTab(name) {
      this.findActiveTab(name);
      // this.closeName = name
      // this.openTab(this.activeName, {})
      // this.$nextTick(() => {
      //   this.$el.querySelector('.znl-lable' + name.split('/')[1]).parentElement.style.display = 'none'
      // })
      this.$store.commit("removeTab", name);
      this.$router.replace({name: this.activeTab});
      this.rightMenuConfig.show = false;
      // 弹出框处理
      // this.$parent.$children[0].indexs = []
      // _.each(this.tabs, item => {
      //   this.$parent.$children[0].indexs = _.union(this.indexs, [item.name])
      // })
      // if (this.$store.state.loadingInstance) {
      //   this.$store.state.loadingInstance.close()
      // }
    },
    closeAllTab() {
      this.$store.commit("removeAllTab");
      this.$router.replace({name: this.activeTab});
      this.rightMenuConfig.show = false;
      // 弹出框处理
      this.$parent.$children[0].indexs = [];
      if (this.$store.state.loadingInstance) {
        this.$store.state.loadingInstance.close();
      }
    },
    refreshGrid(component) {
      // 解决flexgrid tab切换后无法正常渲染问题
      if (!component) return;
      if (component.grid && component.grid.invalidate) {
        component.grid.invalidate();
      }
      if (component.$children.length) {
        _.each(component.$children, child => {
          this.refreshGrid(child);
        });
      }
    },
    onContextMenuHide(e) {
      e.preventDefault();

      this.rightMenuConfig.show = false;
      this.$emit("hide:contextmenu", e);
      this.$nextTick(() => {
        this.$emit("hidden:contextmenu", e);
      });
    },
    onContextMenuShow(e) {
      // e.preventDefault()
      _.each(e.path, el => {
        // hasClass(el, 'el-tabs__item') && (this.rightMenuConfig.show = true)
        hasClass(el, "el-tabs__item");
      });
      this.editingRowIndex = -1;
      _.each(e.path, el => {
        hasClass(el, "el-tabs__item") && el.click();
      });

      // this.rightMenuConfig.show = true
      // this.$emit('show:contextmenu', e)
      // this.$nextTick(() => {
      //   let { document, menuHeight, menuWidth } = {
      //     document: getDocumentRect(),
      //     menuHeight: this.$refs.contextMenu.offsetHeight,
      //     menuWidth: this.$refs.contextMenu.offsetWidth
      //   }
      //   if (event.pageY + menuHeight > document.height) {
      //     this.$set(this.rightMenuConfig, 'top', e.pageY - menuHeight)
      //   } else {
      //     this.$set(this.rightMenuConfig, 'top', e.pageY)
      //   }
      //   if (event.pageX + menuWidth > document.width) {
      //     this.$set(this.rightMenuConfig, 'left', e.pageX - menuWidth)
      //   } else {
      //     this.$set(this.rightMenuConfig, 'left', e.pageX)
      //   }

      //   this.$emit('shown:contextmenu', e)
      // })
    },
    tabFixChange(index) {
      this.tabFixChangeIndex = index;
      this.$post(
        CONFIG.deleteURL,
        {RouteName: this.tabs[index].title},
        res => {
          if (res === 1) {
            this.tabs[index].closable = !this.tabs[index].closable;
            this.$message({
              message: "解除锁定成功",
              type: "success",
              duration: 1000
            });
          } else {
            this.$message({
              message: "解除锁定失败",
              type: "error",
              duration: 1000
            });
          }
        }
      );
    },
    tabFixChangeRight(index) {
      _.delay(() => {
        this.rightMenuConfig.show = false;
        if (this.tabs[index].closable) {
          this.$post(
            CONFIG.saveURL,
            {RouteName: this.tabs[index].title},
            res => {
              if (res === 1) {
                this.tabs[index].closable = !this.tabs[index].closable;
                this.$message({
                  message: "锁定成功",
                  type: "success",
                  duration: 1000
                });
              } else {
                this.$message({
                  message: "锁定失败",
                  type: "error",
                  duration: 1000
                });
              }
            }
          );
        } else {
          this.rightMenuConfig.show = false;
          this.$post(
            CONFIG.deleteURL,
            {RouteName: this.tabs[index].title},
            res => {
              if (res === 1) {
                this.tabs[index].closable = !this.tabs[index].closable;
                this.$message({
                  message: "解除锁定成功",
                  type: "success",
                  duration: 1000
                });
              } else {
                this.$message({
                  message: "解除锁定失败",
                  type: "error",
                  duration: 1000
                });
              }
            }
          );
        }
      }, 50);
    },

    onRes() {
      this.$confirm('确定退出系统吗?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        // localStorage.clear();
        this.onClearStorage()
        delCookie('login_token');
        this.$router.push({name:"LoginView",query: {
          date:new Date().getTime()
        }})
        this.$store.state.tabs = [];
      })
    },

    getDate(date) {
      return this.formatDate(date, 'yyyy-MM-dd')
    }
  },
  created() {
    console.log(this.tabs)
  },
  mounted() {
    _.delay(() => {
      this.showTabs = !isClient();
    }, 500);

    const headersDom = document.getElementsByClassName('p-header-tabs')[0]
    const firstDom = headersDom.firstChild
    firstDom.setAttribute('id','p-header-tabs-contents');
  }
};
</script>


<style lang="scss">
@import "app/assets/styles/variables";

.znl-content {
  position: relative;
}

.znl-header-tabs.el-tabs {
  overflow: hidden;
  outline: none !important;
  border: none;

  & > .el-tabs__header {
    // display: none; //隐藏顶部页签
    overflow: hidden;
    height: 50px;
    line-height: 50px;
    background: #f0f0f0;
    // margin-bottom: 0;
    // margin-top: 0;
    border: none;
    box-sizing: border-box;
    transition: none !important;

    .el-tabs__nav-wrap {
      margin-bottom: 0px;
      padding: 0 115px 0 28px;
      transition: none !important;

      .el-tabs__nav-prev,
      .el-tabs__nav-next {
        width: 28px;
        height: 50px;
        background-color: #f0f0f0;
        color: #666;

        i {
          position: absolute;
          top: 50% !important;
          left: 50% !important;
          transform: translateX(-50%) translateY(-50%) scale(0.8);

          :hover {
            background-color: transparent;
            color: #ee7700;
          }
        }
      }

      .el-tabs__nav-prev {
        left: 0;
        top: 5px;
        // i{
        //   transform: scale(0.8);
        // }
      }

      .el-tabs__nav-next {
        right: 90px;
        top: 5px;
        // i{
        //   transform: scale(0.8);
        // }
      }

      .el-tabs__nav-scroll {
        width: 100%;
        margin: 0 auto;
        transition: none !important;

        .el-tabs__nav {
          display: flex !important;
          align-items: flex-end !important;
          transition: none !important;

          .el-tabs__item {
            // padding: 0 20px;
            font-size: 14px;
            color: #000;
            background-color: #e6e6e6;
            height: 50px;
            line-height: 50px;
            // margin-top: 10px;
            border: none !important;
            position: relative;
            margin-right: 3px;
            border-top-left-radius: 3px !important;
            border-top-right-radius: 3px !important;
            transition: none !important;

            &.is-active {
              color: #ee7700;
              font-weight: 900;
              height: 50px;
              line-height: 50px;
              // top: 0px;
              background-color: white;
              // border-bottom: 3px solid $brand-primary !important;
              // border-top-left-radius: 4px!important;
              // border-top-right-radius: 4px!important;
            }

            .el-icon-close {
              // background: #DFE3E6;
              color: #4d4d4d;
              height: 14px;
              line-height: 14px;
            }

            .el-icon-close:before {
              transform: scale(0.5);
            }

            .el-icon-close:hover {
              background-color: #ee7700;
              color: #ffffff;
            }

            .icon-nav_lock_ic,
            .icon-nav_unlock_ic {
              // tab栏锁图标样式
              font-size: 10px;

              &:hover {
                color: #ee7700;
              }
            }

            .icon-nav_unlock_ic {
              // visibility: hidden!important;
              display: none !important;
            }

            &:hover {
              .icon-nav_unlock_ic {
                // visibility: visible!important;
              }
            }
          }
        }
      }
    }

    .is-scrollable {
      // width: 94%;
      margin: 0 auto;
    }

    .el-tabs__nav-prev {
      line-height: 50px;
      left: 8px;

      .el-icon-arrow {
        color: #999999 !important;
      }

      .el-icon-arrow-left {
        // transform: scale(0.8);
        &:hover {
          background-color: transparent;
          padding: 2px;
          color: #ee7700;
        }
      }
    }

    .el-tabs__nav-next {
      line-height: 50px;
      right: 8px;

      .el-icon-arrow-right {
        // transform: scale(0.8);
        &:hover {
          background-color: transparent;
          padding: 2px;
          color: #ee7700;
        }
      }
    }

    .el-tabs__item {
      padding: 0 10px 0 15px;
      font-size: 14px;
      color: #000;
      background-color: #e6e6e6;

      border: none !important;
      position: relative;
      margin-right: 3px;
      border-top-left-radius: 4px !important;
      border-top-right-radius: 4px !important;
      transition: none;

      .el-icon-close {
        // background: #DFE3E6;
        color: #4d4d4d;
        height: 14px;
        line-height: 14px;
      }

      .el-icon-close:before {
        transform: scale(0.5);
      }

      .el-icon-close:hover {
        background-color: #ee7700;
        color: #ffffff;
      }

      &.is-active {
        color: #ee7700;
        font-weight: 900;
        height: 50px;
        line-height: 50px;
        transform: translateY(2px);
        background-color: white;
      }
    }
  }

  & > .el-tabs__content {
    overflow: inherit;

    .el-tab-pane {
      height: 100%;
      overflow: hidden;

      .index,
      & > div {
        padding: 0 !important;
      }
    }
  }
}

.el-tabs--border-card > .el-tabs__content {
  padding: 0px;
}

.znl-right-menu {
  margin: 0;
  background-color: $white;
  border: 1px solid #d1dbe5;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.12);
  padding: 6px 0;
  top: 0;
  left: 0;
  min-width: 100px;
  position: absolute;
  z-index: 9999;

  &:focus {
    outline: none;
  }

  li {
    padding: 0;
    margin: 0;
    min-width: 120px;
    list-style: none;
    position: relative;

    &.divider {
      height: 1px;
      margin: 9px 0;
      overflow: hidden;
      background-color: $gray-lighter;
    }

    .iconfont {
      position: absolute;
      top: 6px;
      left: 10px;
      font-size: 12px;
      z-index: 999;
    }

    .icon-nav_close_btn_ic,
    .icon-nav_all-close_btn_ic {
      top: 4px;
      font-size: 16px;
      left: 8px;
    }

    a,
    span {
      padding: 0 10px 0 30px;
      color: $gray-darker;
      display: block;
      height: 26px;
      line-height: 26px;
      text-decoration: none;
      position: relative;
      cursor: pointer;

      .fa-icon {
        height: 14px;
        color: $gray-light;
        position: absolute;
        left: 10px;
        top: 7px;
      }

      &:hover {
        background-color: $brand-primary;
      }
    }

    span {
      color: lighten($black, 60%);

      .fa-icon {
        color: lighten($black, 80%);
      }
    }
  }
}

.znl-tabs-container {
  & > .znl-header-tabs {
    & > .el-tabs__header {
      display: none; //隐藏顶部页签
    }
  }

  &.hideTabs {
    height: 100%;

    .znl-header-tabs,
    .el-tabs__content {
      height: 100%;
    }
  }

  &.showTabs {
    & > .znl-header-tabs {
      & > .el-tabs__header {
        display: block; //显示顶部页签
      }
    }
  }

  #tab-LoginView {
    display: none;
  }
}

// 显示登陆信息
.znl-tabs-container {
  position: relative;

  .znl-tabs-container_info {
    position: absolute;
    top: 0;
    right: 0;
  }
  .znl-tabs-container_info-box {
    display: flex;
    height: 40px;
    align-content: center;
    align-items: center;
    span {
      display: inline-block;
      margin-right: 10px;
      cursor: pointer;
      color: #fff;
    }
  }

  .qq-link {
    display: inline-block;
    width: 20px;
    height: 20px;
    background-image: url("/static/imgs/qq.svg");
    background-size: contain;
    vertical-align: -4px;
  }

}

</style>
